<template>
  <div class="vip">
    <div class="vip_header">
      <div class="info">
        <div class="user">
          <img :src="$store.getters.getInfo.member_avatar" />
          <div class="state">
            <h1>{{ $store.getters.getInfo.member_nickname }}</h1>
            <span v-if="$store.getters.getInfo['19jiang_inviter_level_id'] == 0"
              >未开通会员</span
            >
          </div>
        </div>
        <div
          class="include"
          v-if="$store.getters.getInfo['19jiang_inviter_level_id'] == 0"
        >
          VIP会员 低至0.54元/天
        </div>
      </div>
    </div>

    <div class="vip_RightsAndInterests">
      <div class="vip_title">
        <h2>会员专享权益</h2>
        <span
          @click="
            $router.push({
              name: 'VipDetails',
            })
          "
          >会员成长</span
        >
      </div>
      <ul class="vip_RI-ul">
        <li>
          <svg
            width="84"
            height="84"
            viewBox="0 0 84 84"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="42" cy="42" r="42" fill="#FDF8EE" />
            <rect
              x="18"
              y="27"
              width="48"
              height="14"
              rx="2"
              stroke="#C29647"
              stroke-width="3"
            />
            <rect
              x="25"
              y="41"
              width="34"
              height="25"
              rx="2"
              stroke="#C29647"
              stroke-width="3"
            />
            <path
              d="M42 26.1755C43.4505 22.8776 48.2661 16.3892 55.9246 18.368C58.2454 18.9176 61.4944 21.7234 55.9246 27"
              stroke="#C29647"
              stroke-width="3"
            />
            <path
              d="M41.5996 26.1755C40.1491 22.8776 35.3335 16.3892 27.675 18.368C25.3542 18.9176 22.1052 21.7234 27.675 27"
              stroke="#C29647"
              stroke-width="3"
            />
            <path d="M42 25.0967V65.3824" stroke="#C29647" stroke-width="3" />
          </svg>
          <div class="info">
            <h2>产品礼/课程礼</h2>
            <span>365天纹绣学习</span>
          </div>
        </li>

        <li>
          <svg
            width="84"
            height="84"
            viewBox="0 0 84 84"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="42" cy="42" r="42" fill="#FDF8EE" />
            <path
              d="M24.1372 43.2402C23.7621 42.8651 23.5514 42.3564 23.5514 41.826L23.5517 25.5513C23.5517 24.4467 24.4472 23.5513 25.5517 23.5513L41.826 23.5514C42.3564 23.5514 42.8651 23.7621 43.2402 24.1372L68.7129 49.61C69.494 50.391 69.494 51.6574 68.7129 52.4384L52.4384 68.713C51.6573 69.494 50.391 69.494 49.6099 68.713L24.1372 43.2402Z"
              stroke="#C29647"
              stroke-width="3"
            />
            <circle
              cx="36.0518"
              cy="35.0513"
              r="5.5"
              stroke="#C29647"
              stroke-width="3"
            />
          </svg>
          <div class="info">
            <h2>日常色料仪器</h2>
            <span>专享折扣</span>
          </div>
        </li>
        <li>
          <svg
            width="84"
            height="84"
            viewBox="0 0 84 84"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="42" cy="42" r="42" fill="#FDF8EE" />
            <path
              d="M18 25H65V37.5C65 37.5 62 38.5 62 42C62 45.5 65 46.5 65 46.5V59H18V46.5C18 46.5 21 45.5 21 42C21 38.5 18 37.5 18 37.5V25Z"
              stroke="#C29647"
              stroke-width="3"
              stroke-linejoin="round"
            />
            <path
              d="M27 25.5V31.5"
              stroke="#C29647"
              stroke-width="3"
              stroke-linecap="round"
            />
            <path
              d="M27 53V59"
              stroke="#C29647"
              stroke-width="3"
              stroke-linecap="round"
            />
            <path
              d="M55 25.5V31.5"
              stroke="#C29647"
              stroke-width="3"
              stroke-linecap="round"
            />
            <path
              d="M55 53V59"
              stroke="#C29647"
              stroke-width="3"
              stroke-linecap="round"
            />
            <path
              d="M42.38 35.48V50.12H41.02V48.86H34.94V50.12H33.58V35.48H37.28V32.58H38.68V35.48H42.38ZM34.94 47.56H37.28V42.66H34.94V47.56ZM38.68 47.56H41.02V42.66H38.68V47.56ZM34.94 41.38H37.28V36.8H34.94V41.38ZM38.68 36.8V41.38H41.02V36.8H38.68ZM44.06 33.74H50.46V34.8C49.8467 36.96 49.2 38.9 48.52 40.62C49.9067 42.62 50.6067 44.26 50.62 45.54C50.62 46.5667 50.3733 47.2933 49.88 47.72C49.3467 48.1467 48.3067 48.36 46.76 48.36L46.34 46.9C46.9133 46.9667 47.3533 47 47.66 47C48.3533 46.96 48.7933 46.8467 48.98 46.66C49.1667 46.46 49.2667 46.0867 49.28 45.54C49.2667 44.3133 48.5333 42.6733 47.08 40.62C47.7467 38.98 48.38 37.12 48.98 35.04H45.44V51.02H44.06V33.74Z"
              fill="#C29647"
            />
          </svg>

          <div class="info">
            <h2>会员包邮券</h2>
            <span>购物无忧</span>
          </div>
        </li>
        <li>
          <svg
            width="84"
            height="84"
            viewBox="0 0 84 84"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="42" cy="42" r="42" fill="#FDF8EE" />
            <path
              d="M19.6031 34.2103C19.3192 32.7931 20.5862 31.5519 21.9973 31.8649L32.3891 34.1702C33.2243 34.3555 34.0849 33.989 34.5301 33.2584L40.2921 23.8027C41.0715 22.5237 42.9285 22.5237 43.7079 23.8027L49.4699 33.2584C49.9151 33.989 50.7757 34.3555 51.6109 34.1702L62.0027 31.8649C63.4138 31.5519 64.6808 32.7931 64.3969 34.2103L58.7506 62.3929C58.5633 63.3274 57.7426 64 56.7895 64H27.2105C26.2574 64 25.4367 63.3274 25.2494 62.3929L19.6031 34.2103Z"
              stroke="#C29647"
              stroke-width="3"
              stroke-linejoin="round"
            />
            <path
              d="M35 56.5H49"
              stroke="#C29647"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div class="info">
            <h2>会员日福利</h2>
            <span>365天纹绣学习</span>
          </div>
        </li>

        <template v-if="shwoTab">
          <li>
            <svg
              width="84"
              height="84"
              viewBox="0 0 84 84"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="42" cy="42" r="42" fill="#FDF8EE" />
              <circle
                cx="42"
                cy="42"
                r="24"
                stroke="#C29647"
                stroke-width="3"
              />
              <path
                d="M40.2311 29.3527C40.9815 27.9304 43.0185 27.9304 43.7689 29.3527L46.4315 34.3992C46.7209 34.9477 47.2483 35.3309 47.8594 35.4366L53.4817 36.4094C55.0663 36.6836 55.6957 38.6209 54.5749 39.7741L50.5982 43.8658C50.166 44.3105 49.9645 44.9306 50.0528 45.5444L50.865 51.1922C51.0939 52.7839 49.4459 53.9812 48.0028 53.2716L42.8825 50.7539C42.326 50.4803 41.674 50.4803 41.1175 50.7539L35.9972 53.2716C34.5541 53.9812 32.9061 52.7839 33.135 51.1922L33.9472 45.5444C34.0355 44.9306 33.834 44.3105 33.4018 43.8658L29.4251 39.7741C28.3043 38.6209 28.9337 36.6836 30.5183 36.4094L36.1406 35.4366C36.7517 35.3309 37.2791 34.9477 37.5685 34.3992L40.2311 29.3527Z"
                stroke="#C29647"
                stroke-width="3"
              />
            </svg>

            <div class="info">
              <h2>积分兑换</h2>
              <span>产品0元得</span>
            </div>
          </li>
          <li>
            <svg
              width="84"
              height="84"
              viewBox="0 0 84 84"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="42" cy="42" r="42" fill="#FDF8EE" />
              <path
                d="M42 52L20.8944 41.4472C19.5646 40.7823 18 41.7493 18 43.2361V62C18 63.1046 18.8954 64 20 64H64C65.1046 64 66 63.1046 66 62V43.2361C66 41.7493 64.4354 40.7823 63.1056 41.4472L42 52ZM42 52L65 63"
                stroke="#C29647"
                stroke-width="3"
              />
              <path
                d="M57 45V23C57 21.8954 56.1046 21 55 21H29C27.8954 21 27 21.8954 27 23V45"
                stroke="#C29647"
                stroke-width="3"
              />
              <path
                d="M38 26.9497L42.2427 31.1923L46.4853 26.9497"
                stroke="#C29647"
                stroke-width="3"
                stroke-linecap="round"
              />
              <path
                d="M36 33H48"
                stroke="#C29647"
                stroke-width="3"
                stroke-linecap="round"
              />
              <path
                d="M36 39H48"
                stroke="#C29647"
                stroke-width="3"
                stroke-linecap="round"
              />
              <path
                d="M42 32L42 45"
                stroke="#C29647"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>

            <div class="info">
              <h2>推荐奖励</h2>
              <span>分享返现</span>
            </div>
          </li>
          <li>
            <svg
              width="84"
              height="84"
              viewBox="0 0 84 84"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="42" cy="42" r="42" fill="#FDF8EE" />
              <path
                d="M23 53.5V42V38C23 27.5066 31.5066 19 42 19V19C52.4934 19 61 27.5066 61 38V46C61 56.4934 52.4934 65 42 65V65H41.5"
                stroke="#C29647"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M37 49C37 49 39 50.5 42 50.5C45 50.5 47 49 47 49"
                stroke="#C29647"
                stroke-width="3"
                stroke-linecap="round"
              />
              <path
                d="M18 37V47"
                stroke="#C29647"
                stroke-width="3"
                stroke-linecap="round"
              />
              <path
                d="M66 37V47"
                stroke="#C29647"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>

            <div class="info">
              <h2>专属客服</h2>
              <span>贴心守护</span>
            </div>
          </li>
          <li>
            <svg
              width="84"
              height="84"
              viewBox="0 0 84 84"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="42" cy="42" r="42" fill="#FDF8EE" />
              <rect
                x="19"
                y="32"
                width="46"
                height="32"
                rx="2"
                stroke="#C29647"
                stroke-width="3"
                stroke-linejoin="round"
              />
              <path
                d="M57 35C57 26.7157 50.2843 20 42 20C33.7157 20 27 26.7157 27 35"
                stroke="#C29647"
                stroke-width="3"
                stroke-linecap="round"
              />
              <path
                d="M31 52V45L35 52V45M42.5 45.5H39V48.5M42.5 52H39V48.5M42.5 48.5H39M45.5 45.5L47.5 52L49 45.5L51 52L52.5 45.5"
                stroke="#C29647"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div class="info">
              <h2>新品优享</h2>
              <span>尝鲜特惠</span>
            </div>
          </li>
        </template>
      </ul>
      <div
        class="more show"
        :class="!shwoTab ? 'show' : 'hide'"
        @click="shwoTab = !shwoTab"
      >
        {{ !shwoTab ? "展开" : "收起" }}
      </div>
    </div>

    <div class="vip_package">
      <div class="vip_title">
        <h2>会员套餐选择</h2>
      </div>

      <ul class="package-ul">
        <li
          @click="chooeseVip(item, index)"
          v-for="(item, index) in vipList"
          :key="item.goods_id"
          :class="vipActiveCur == index ? 'act' : ''"
        >
          <h2>{{ item.level_name }}</h2>
          <div class="ul">
            <span class="include" v-for="(items, ii) in item.desc" :key="ii"
              ><i></i>{{ items }}</span
            >
          </div>
          <div class="price">
            <span>￥</span>
            <b>{{ item.goods_price }}</b>
          </div>
        </li>
      </ul>

      <div class="buy">
        <div
          @click="
            $router.push({
              name: 'HomeGoodsdetail',
              query: { goods_id: vipActiveItem.goods_id },
            })
          "
        >
          ¥{{ vipActiveItem.goods_price }}/年 立即加入
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from "@get/http";

export default {
  data() {
    return {
      vipActiveCur: 0,
      vipActiveItem: {},
      shwoTab: false,
      vipList: [],
    };
  },
  methods: {
    chooeseVip(item, index) {
      this.vipActiveCur = index;
      this.vipActiveItem = item;
    },
  },
  async created() {
    let { list } = await post("/lw.MemberGoodsInviterWelfare/getList");
    if (list) {
      this.vipList = list;
      this.vipActiveItem = list[0];
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.vip {
  width: rem(750);
  margin: 0 auto;
}
.vip_header {
  background: url("~@image/vip/vipbeijing.jpg") no-repeat;
  background-size: rem(750) rem(346);
  width: rem(750);
  height: rem(346);
  position: relative;
  .info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: rem(690);
    height: rem(220);
    background: url("~@image/vip/vipbeijing2.jpg") no-repeat;
    background-size: rem(690) rem(220);
    margin: 0 auto;
    border-radius: rem(32) rem(32) 0 0;
    padding: rem(33) rem(42);
    box-sizing: border-box;
    .user {
      display: flex;

      img {
        border: rem(2) solid #d3b79b;
        box-sizing: border-box;
        width: rem(74);
        height: rem(74);
        border-radius: 50%;
        margin-right: rem(10);
      }
      .state {
        h1 {
          font-weight: bold;
          font-size: rem(28);
          color: #ffffff;
        }
        span {
          font-size: rem(24);
          color: #ffffff;
          opacity: 0.5;
        }
      }
    }
    .include {
      margin-top: rem(34);
      padding: 0 rem(16);
      box-sizing: border-box;
      height: rem(44);
      line-height: rem(44);
      background: #6a6a6a;
      color: #ffffff;
      font-size: rem(22);
      border-radius: rem(22);
      display: inline-block;
    }
  }
}
.vip_RightsAndInterests {
  background: #fff;
  padding-bottom: rem(40);
}
.vip_RI-ul {
  display: grid;

  grid-template-columns: 1fr 1fr;
  padding: 0 rem(30);
  li {
    display: flex;
    padding: rem(20) 0;
    svg {
      width: rem(84);
      height: rem(84);
      margin-right: rem(16);
    }
    .info {
      h2 {
        color: #222222;
        font-size: rem(30);
      }
      span {
        color: #c29647;
        font-size: rem(24);
      }
    }
  }
}

.vip_package {
  background: #fff;
  margin-top: rem(24);
  .package-ul {
    display: flex;
    justify-content: space-between;
    padding: rem(20) rem(30) rem(30);
    box-sizing: border-box;
    .act {
      background: #fdf8ee;
      h2 {
        color: #c29647;
      }
      .include {
        color: #e4b664;
        i {
          background: #e4b664;
        }
      }
    }
    li {
      width: rem(334);
      height: rem(272);
      border: 1px solid #e6e6e6;
      box-sizing: border-box;
      border-radius: rem(20);
      background: #f9f9f9;
      padding: rem(24);
      h2 {
        @include ell;
        color: #222222;
        font-weight: bold;
        font-size: rem(30);
      }
      .ul {
        height: rem(80);
      }
      .include {
        @include ell;
        i {
          display: inline-block;
          margin-right: rem(4);
          background: #989898;
          border-radius: 50%;
          width: rem(10);
          height: rem(10);
        }
        color: #989898;
        font-size: rem(24);
      }
      .price {
        color: #ff4848;
        font-weight: bold;
        span {
          font-size: rem(30);
        }
        b {
          font-size: rem(64);
        }
      }
    }
  }
}

.buy {
  background: #ffffff;
  width: 100%;
  height: rem(158);
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    background: linear-gradient(90deg, #ffe9bc 0%, #f0cb8b 100%);
    border-radius: rem(55);
    width: rem(690);
    height: rem(110);
    color: #512c19;
    font-weight: bold;
    font-size: rem(30);
    line-height: rem(110);
    text-align: center;
    margin: 0 auto;
  }
}

.more {
  display: block;
  color: #666666;
  font-size: rem(24);
  text-align: center;
  margin-top: rem(20);
}
.show {
  background: url("~@image/vip/show.png") no-repeat rem(410) center;
  background-size: rem(16) rem(8);
}
.hide {
  background: url("~@image/vip/hide.png") no-repeat rem(410) center;
  background-size: rem(16) rem(8);
}

.vip_title {
  display: flex;
  align-items: center;
  padding: rem(40) rem(24) rem(20);
  h2 {
    font-weight: bold;
    color: #222222;
    font-size: rem(30);
    flex: 1;
  }
  span {
    color: #989898;
    background: url("~@image/vip/more.png") no-repeat right center;
    background-size: rem(8) rem(16);
    padding-right: rem(18);
  }
}
</style>
